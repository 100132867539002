import "@fontsource/inter/400.css";
import "@fontsource/inter/900.css";

window.addEventListener("load", () => {
    const mail = document.querySelector("[data-mail]");
    const tel = document.querySelector("[data-tel]");

    // replace mail
    const mailAnchor = document.createElement("a");
    mailAnchor.innerHTML = "ilse@slashcommunicatie.nl";
    mailAnchor.setAttribute("href", "mailto:ilse@slashcommunicatie.nl");
    mail.parentNode.replaceChild(mailAnchor, mail);

    // replace tel
    const telAnchor = document.createElement("a");
    telAnchor.innerHTML = "06 129 79 644";
    telAnchor.setAttribute("href", "tel:+31612979644");
    tel.parentNode.replaceChild(telAnchor, tel);
});
